import validate from "/app/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@8.57.1_ioredis@5.4.1_magicast@0_mzdw4frkydvsys37fnbo6lz23q/node_modules/nuxt/dist/pages/runtime/validate.js";
import reset_45alerts_45global from "/app/middleware/resetAlerts.global.js";
import manifest_45route_45rule from "/app/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@8.57.1_ioredis@5.4.1_magicast@0_mzdw4frkydvsys37fnbo6lz23q/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  reset_45alerts_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  authenticated: () => import("/app/middleware/authenticated.js")
}